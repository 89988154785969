import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Box,
  Container,
  Button,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import { ArrowDownIcon, ArrowUpIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';
import '../styles/contact.scss';

const Contact = () => {
  const [currentTab, setCurrentTab] = useState(null);
  const [captcha, checkCaptcha] = useState(false);
  /**
   * @param { 'fertilizers' | 'nutrient' } kind
   */
  const tabSelector = (kind) => {
    if (kind === currentTab) {
      setCurrentTab(null);
    } else {
      setCurrentTab(kind);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (captcha) {
      emailjs
        .sendForm(
          `service_3z3x4r8`,
          `template_atuhcsa`,
          e.target,
          process.env.GATSBY_EMAILJS_USER_ID
        )
        .then(
          (result) => {
            alert('Message Sent, We will get back to you shortly', result.text);
          },
          (error) => {
            alert('An error occurred, Please try again', error.text);
          }
        );
    } else {
      alert('Please verify the reCaptcha');
    }
  };

  return (
    <Layout>
      <SEO
        title="Contact Ostara for Crystal Green Fertilizer and Nutrient Solutions | Ostara"
        description="Reach out to our team to learn how our sustainable nutrient solutions can help solve your nutrient challenges with fertilizer or nutrient recovery technology."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Ostara Contact"
      />
      <main className="contact-page">
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[5, 5, 5, 10]}
            >
              <Box
                marginTop={[5, 5, 5, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>Contact</h1>
                  <p style={{ maxWidth: 550 }}>
                    Reach out to our team to learn how our sustainable nutrient
                    solutions can help solve your nutrient challenges.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../images/about-hero-img-min.jpeg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        <Container className="contact-container" maxW="1121px">
          <Heading as="h3" textAlign="center" className="contact-form__title">
            Are you interested in…?
          </Heading>
          <Box className="contact-tab">
            <Text
              onClick={() => tabSelector('fertilizers')}
              fontWeight={300}
              className={currentTab === 'fertilizers' ? 'active' : ''}
            >
              Fertilizers
              {currentTab === 'fertilizers' ? (
                <ArrowUpIcon color="#7fba00" />
              ) : (
                <ArrowDownIcon color="#044606" />
              )}
            </Text>
            <Text
              onClick={() => tabSelector('nutrient')}
              fontWeight={300}
              className={currentTab === 'nutrient' ? 'active' : ''}
            >
              Nutrient Recovery
              {currentTab === 'nutrient' ? (
                <ArrowUpIcon color="#7fba00" />
              ) : (
                <ArrowDownIcon color="#044606" />
              )}
            </Text>
          </Box>
          <form onSubmit={handleSubmit}>
            {currentTab === 'fertilizers' ? (
              <FormControl className="main-form">
                <Input
                  className="main-form__field"
                  type="text"
                  name="crop"
                  placeholder="Crop"
                />
                <Input
                  type="text"
                  className="main-form__field"
                  name="farm-size"
                  placeholder="Farm Size"
                />
              </FormControl>
            ) : null}

            {currentTab === 'nutrient' ? (
              <FormControl className="main-form">
                {/* <RadioGroup name="nutrient-type">
                  <Radio
                    marginRight={50}
                    colorScheme="green"
                    value="Municipality"
                  >
                    Municipality
                  </Radio>
                  <Radio colorScheme="green" value="Industrial">
                    Industrial
                  </Radio>
                </RadioGroup> */}
                <Input
                  marginTop={6}
                  type="text"
                  name="facility-type"
                  className="main-form__field"
                  placeholder="Facility Type"
                />
                <Input
                  marginTop={6}
                  type="text"
                  name="installation"
                  className="main-form__field"
                  placeholder="Name of the Installation"
                />
              </FormControl>
            ) : null}

            <Box className="contact-main-form">
              <Heading
                as="h3"
                textAlign="center"
                className="contact-form__title"
              >
                Let’s Talk About Your Nutrient Challenges
              </Heading>
              <FormControl className="main-form">
                <Input
                  type="text"
                  name="name"
                  className="main-form__field"
                  placeholder="Name"
                  required
                />
                <Input
                  type="email"
                  name="email"
                  className="main-form__field"
                  placeholder="Email"
                  required
                />
                <Input
                  type="phone"
                  name="phone"
                  className="main-form__field"
                  placeholder="Phone"
                />
                <Textarea
                  rows={6}
                  name="message"
                  className="main-form__field"
                  placeholder="Message"
                />
                <Box className="submit-box">
                  <ReCAPTCHA
                    sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY}
                    onChange={() => checkCaptcha(true)}
                    name="g-recaptcha-response"
                  />

                  <Button
                    height={45}
                    type="submit"
                    fontSize="sm"
                    fontWeight={600}
                    color="#044606"
                    bg="#7fba00"
                    _hover={{
                      bg: '#044606',
                      color: 'white',
                    }}
                    rightIcon={<ChevronRightIcon w={8} h={8} />}
                    className="contact-btn"
                  >
                    <h5>CONTACT</h5>
                  </Button>
                </Box>
              </FormControl>
            </Box>
          </form>
          <Box className="contact-branches">
            <div className="contact-branch">
              <Heading as="h4" className="branch__title">
                Headquarters
              </Heading>
              <address className="branch__address">
                490 – 1122 Mainland Street
                <br />
                Vancouver, BC V6B 5L1
                <br />
                Canada
              </address>
            </div>
            <div className="contact-branch">
              <Heading as="h4" className="branch__title">
                USA
              </Heading>
              <address className="branch__address">
                3225 Co Rd 630
                <br />
                Fort Meade, FL 33841
                <br />
                USA
              </address>
            </div>
          </Box>
          <Box className="contact-email">
            Contact us at <a href="mailto:info@ostara.com">info@ostara.com</a>
          </Box>
        </Container>

        <Box marginTop={150} w="100%" className="contact-footer-image" />
      </main>
    </Layout>
  );
};

export default Contact;
